import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageTween from "../../components/imageTween"

const UnityEye = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/unity-eye/">
      <SEO title="UNITY Eye" />

      <section className="home-top">
        <div className="global__padd">
          <div className="home-top__wrapper">
            <h2>
              UNITY <br /> <span>Centre</span>
            </h2>
            <h2>Krakowski Kompleks biznesowy</h2>
          </div>
          <ImageTween
            src={require("../../assets/images/unity-eye-top-image.jpg")}
            srcSet={require("../../assets/images/unity-eye-top-image@2x.jpg")}
            alt=""
          />
        </div>
      </section>

      <section className="unity-eye-top global__padd--bigger">
        <h2>UNITY Eye</h2>
        <p>
          Najlepszy widok na Kraków znajduje się na tarasie na samym szczycie
          UNITY Tower, czyli na 26. piętrze. Wyjedziesz na wysokość 102 metrów w
          zaledwie 45 sekund! Z góry rozpościera się widok na okolicę, a przy
          dobrej widoczności - na Tatry!
        </p>
        <div className="global-section-tiles__tile-soon">
          <span>Wkrótce otwarcie…</span>
        </div>
      </section>
    </Layout>
  )
}

export default UnityEye
